import React from 'react';

export default function Header({ chart }) {
  const days = chart
    .chart_cycles[0]
    .chart_days
    .filter(({ current_chart_day }) => current_chart_day)
    .sort((left, right) => left.day_number - right.day_number);

  return (
    <thead>
      <tr>
        {days.map((day) => (
          <th key={day.id} className="chart-day">
            {day.day_number}
          </th>
        ))}
        <th />
      </tr>
    </thead>
  );
}
