import React, { useState } from 'react';
import { useOnClickOutside } from 'react-hanger';
import {
  checkSession,
  findCorrectedDay,
  find_record,
  isCorrectable,
  isDirty,
  isPresent,
  onSubmit,
} from '../../ChartUtilities';
import StampEntry from './StampEntry';

export function correctionColor(sticker, correctedSticker) {
  return sticker.hex_color;
}

export function showBaby(sticker, correctedSticker) {
  // if (isPresent(correctedSticker)) return correctedSticker.visible_baby;
  return sticker.visible_baby;
}

export function showCorrection(fupActive, sticker, currentSticker, correctedSticker) {
  if (!fupActive && sticker.id !== currentSticker.id) return false;
  if (correctedSticker.id == sticker.id) return false;
  return sticker.hex_color != correctedSticker.hex_color;
}

// correctedSticker -> the originally charted value or the value which has been corrected
export function showStamp(sticker, correctedSticker) {
  return isPresent(sticker) || isPresent(correctedSticker);
}

export function showStampTextCorrection(fupActive, sticker, currentSticker, correctedSticker) {
  if (!fupActive && sticker.id !== currentSticker.id) return false;
  if (!correctedSticker) return false;
  return sticker.select_name !== correctedSticker.select_name;
}

export function stampColor(sticker, correctedSticker, debug) {
  return correctedSticker.hex_color;
}

export function stampText(sticker, correctedSticker) {
  return sticker.select_name;
}

export default function StampCell({
  addFup,
  chartDays,
  cycle,
  day,
  form,
  fupActive,
  keys,
  pushDay,
  setAddFup,
  stickers,
  subscriptionActive,
}) {
  const [showStampEntry, setShowStampEntry] = useState(false);
  const correctedDay = findCorrectedDay(day, chartDays);

  const onClick = async () => {
    checkSession();

    if (!subscriptionActive) return;

    if (addFup) {
      const fup = form.getValues('fup');
      form.setValue('fup', !fup);
      setAddFup(false);

      await submitForm();

      return;
    }

    if (!isCorrectable(fupActive, day)) return;

    setShowStampEntry(!showStampEntry);
  }

  const submitForm = async () => {
    if (isDirty(form, day)) {
      const update = async (chart_day) => await onSubmit(`/app/chart_days/${chart_day.id}`, { chart_day });
      // TODO: this should be the correct call
      // await form.handleSubmit(update);
      const chartDay = form.getValues();
      const { data: updatedDay } = await update(chartDay);

      pushDay(day.id, updatedDay);
    }

    setShowStampEntry(false);
  }

  const outsideClickRef = useOnClickOutside(() => {
    if (showStampEntry) submitForm();
  });

  const stickerId = form.watch('sticker_id');
  const formSticker = find_record(stickers, stickerId);
  const currentSticker = find_record(stickers, day.sticker_id);
  const correctedSticker = find_record(stickers, correctedDay.sticker_id);

  const babyClass = showBaby(formSticker, correctedSticker) ? 'show-baby' : '';
  const stampCorrectionClass = showCorrection(fupActive, formSticker, currentSticker, correctedSticker) ? 'correction' : '';
  const textCorrectionClass = showStampTextCorrection(fupActive, formSticker, currentSticker, correctedSticker) ? 'stamp-text-correction' : '';

  return (
    <td
      onClick={onClick}
      ref={outsideClickRef}
    >
      <a className={`chart-box ${showStampEntry ? 'selected' : ''}`}>
        {/* background color */}
        {showStamp(formSticker, correctedSticker)
          ? <div className="chart-day-sticker" style={{ backgroundColor: stampColor(formSticker, correctedSticker, day.id == 8605) }} />
          : null
        }
        {/* correction color */}
        {showCorrection(fupActive, formSticker, currentSticker, correctedSticker)
          ? <div className="chart-day-sticker correction top" style={{ backgroundColor: correctionColor(formSticker, correctedSticker) }} />
          : null
        }
        {/* baby */}
        {showStamp(formSticker, correctedSticker)
          ? <div
              className={`chart-day-sticker stamp-text top ${babyClass} ${stampCorrectionClass}`}
            />
          : null
        }
        {/* text */}
        {showStamp(formSticker, correctedSticker)
          ? <div
              className={`chart-day-sticker stamp-text top ${textCorrectionClass}`}
              dangerouslySetInnerHTML={{__html: stampText(formSticker, correctedSticker) }}
            />
          : null
        }
        {!showStamp(formSticker, correctedSticker)
          ? <div className="chart-placeholder">&nbsp;</div>
          : null
        }
        <div className="breast-self-exam-label">
          BSE
        </div>
        {day.notes
          ? <i className="note-icon fa fa-pencil-square-o" />
          : null
        }
      </a>
      {showStampEntry
        ? <div className="stamp-popover position-absolute container">
            <StampEntry
              correctedDay={correctedDay}
              day={day}
              form={form}
              fupActive={fupActive}
              keys={keys}
              stickers={stickers}
              submitForm={submitForm}
            />
          </div>
        : null
      }
    </td>
  );
}
