import React from 'react';

export default function PPP({ cycle, pdf, register, setValue, submitForm, subscriptionActive }) {
  const registerPPP = register('ppp', {
    disabled: !subscriptionActive,
    onBlur: () => submitForm(),
    onChange: (event) => {
      const [value] = event.target.value.match(/\d(\d)?/) || [''];

      setValue('ppp', value);
    },
  });

  return (
    <td className="ppp-entry p-1 d-flex flex-column justify-content-center border-0">
      <div className="form-group mb-0">
        { pdf
          ? (
            <>
              <label className="w-100 text-center mb-1">
                PPP
              </label>
              <div className="text-center">{cycle.ppp}</div>
            </>
          )
          : <input className="w-100 text-center" placeholder="PPP" {...registerPPP} />
        }
      </div>
    </td>
  );
}