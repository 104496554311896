import React, { useState } from 'react';
import { useOnClickOutside } from 'react-hanger';
import {
  checkSession,
  correctionClass,
  findCorrectedDay,
  find_diagram_side,
  isCorrectable,
  isDirty,
  onSubmit,
  showCode,
} from '../../ChartUtilities';
import CodeEntry from './CodeEntry';

export default function CodeCell({
  addFup,
  day,
  chartDays,
  dischargeObservations,
  dischargeObservationModifiers,
  essentialSameness,
  form,
  fupActive,
  intercourses,
  menstrualFlows,
  observationFrequencies,
  pointOfChangeAnswers,
  pushDay,
  sameAsYesterdayAnswers,
  setAddFup,
  subscriptionActive,
}) {
  const [showDailyEntry, setShowDailyEntry] = useState(false);

  const correctedDay = findCorrectedDay(day, chartDays);

  const submitForm = async () => {
    if (isDirty(form, day)) {
      const update = async (chart_day) => await onSubmit(`/app/chart_days/${chart_day.id}`, { chart_day });
      // TODO: this should be the correct call
      // await form.handleSubmit(update);
      const chartDay = form.getValues();
      const { data: updatedDay } = await update(chartDay);

      pushDay(day.id, updatedDay);
    }

    setShowDailyEntry(false);
  }

  const onClick = async () => {
    checkSession();

    if (!subscriptionActive) return;

    if (addFup) {
      const fup = form.getValues('fup');
      form.setValue('fup', !fup);
      setAddFup(false);

      await submitForm();

      return;
    }

    if (!isCorrectable(fupActive, day)) return;

    setShowDailyEntry(!showDailyEntry);
  }

  const outsideClickRef = useOnClickOutside(() => {
    if (showDailyEntry) submitForm();
  });

  const forgotDay = form.watch('forgot_day');
  const recordingDate = form.watch('recording_date');
  const menstrualFlowIds = form.watch('menstrual_flow_ids');
  const dischargeObservationIds = form.watch('discharge_observation_ids');
  const dischargeObservationModifierIds= form.watch('discharge_observation_modifier_ids');
  const observationFrequencyId = form.watch('observation_frequency_id');
  const intercourseId = form.watch('intercourse_id');
  const sameAsYesterdayAnswerId = form.watch('same_as_yesterday_answer_id');
  const pointOfChangeAnswerId = form.watch('point_of_change_answer_id');

  const firstRow = [
    menstrualFlows
      .filter((menstrualFlow) => showCode(menstrualFlow, menstrualFlowIds, correctedDay.menstrual_flow_ids))
      .map((menstrualFlow ) =>
        [
          correctionClass(fupActive, menstrualFlow, menstrualFlowIds, day.menstrual_flow_ids, correctedDay.menstrual_flow_ids),
          menstrualFlow,
        ]
      ),
  ].flat();

  const firstRowRemovals = firstRow.filter(([className]) => className === 'correction-removed');
  const firstRowRest = firstRow.filter(([className]) => className !== 'correction-removed');

  const secondRow = [
    dischargeObservations
      .filter((dischargeObservation) => showCode(dischargeObservation, dischargeObservationIds, correctedDay.discharge_observation_ids))
      .map((dischargeObservation) =>
        [
          correctionClass(fupActive, dischargeObservation, dischargeObservationIds, day.discharge_observation_ids, correctedDay.discharge_observation_ids),
          dischargeObservation,
        ]
      ),
    dischargeObservationModifiers
      .filter((dischargeObservationModifier) => showCode(dischargeObservationModifier, dischargeObservationModifierIds, correctedDay.discharge_observation_modifier_ids))
      .map((dischargeObservationModifier ) =>
        [
          correctionClass(fupActive, dischargeObservationModifier, dischargeObservationModifierIds, day.discharge_observation_modifier_ids, correctedDay.discharge_observation_modifier_ids),
          dischargeObservationModifier,
        ]
      ),
    observationFrequencies
      .filter((observationFrequency) => showCode(observationFrequency, [observationFrequencyId], [correctedDay.observation_frequency_id]))
      .map((observationFrequency) =>
        [
          correctionClass(fupActive, observationFrequency, [observationFrequencyId], [day.observation_frequency_id], [correctedDay.observation_frequency_id]),
          observationFrequency,
        ]
      ),
  ].flat();

  const secondRowRemovals = secondRow.filter(([className]) => className === 'correction-removed');
  const secondRowRest = secondRow.filter(([className]) => className !== 'correction-removed');

  return (
    <td
      onClick={onClick}
      ref={outsideClickRef}
    >
      <a className={`chart-box ${showDailyEntry ? 'selected' : ''}`}>
        <div className="chart-day-date">
          <span className={day.fup ? `border border-danger rounded px-1` : ''}>
            {recordingDate ? new Date(recordingDate).toLocaleDateString('en-US', { timeZone: 'UTC', day: 'numeric', month: 'numeric', year: '2-digit' }) : ''}
          </span>
        </div>

        {forgotDay
          ? <div className="forgot-day-question-mark">?</div>
          : (
            <div className="chart-day-code">
              {firstRowRemovals.length > 0
                ? (
                  <span className="mr-1">
                    {firstRowRemovals.map(([className, code]) => (
                      <span key={code.id} className={className}>
                        {code.abbreviation}
                      </span>
                    ))}
                  </span>
                ) : null
              }
              {firstRowRest.map(([className, code]) => (
                <span key={code.id} className={className}>
                  {code.abbreviation}
                </span>
              ))}
              <br />
              {secondRowRemovals.length > 0
                ? (
                  <span className="mr-1">
                    {secondRowRemovals.map(([className, code]) => (
                      <>
                        <span key={code.id} className={className}>
                          {code.abbreviation}
                        </span>
                        <wbr />
                      </>
                    ))}
                  </span>
                ) : null
              }
              {secondRowRest.map(([className, code]) => (
                <>
                  <span key={code.id} className={className}>
                    {code.abbreviation}
                  </span>
                  <wbr />
                </>
              ))}
              <br />
            </div>
          )
        }

        <div className="chart-day-optional-code">
          {pointOfChangeAnswers.filter((pointOfChangeAnswer) => showCode(pointOfChangeAnswer, [pointOfChangeAnswerId], [correctedDay.point_of_change_answer_id])).map((pointOfChangeAnswer) => (
            <span
              key={pointOfChangeAnswer.id}
              className={correctionClass(fupActive, pointOfChangeAnswer, [pointOfChangeAnswerId], [day.point_of_change_answer_id], [correctedDay.point_of_change_answer_id])}
              dangerouslySetInnerHTML={{__html: pointOfChangeAnswer.abbreviation }}
            />
          ))}
          {sameAsYesterdayAnswers.filter((sameAsYesterdayAnswer) => showCode(sameAsYesterdayAnswer, [sameAsYesterdayAnswerId], [correctedDay.same_as_yesterday_answer_id])).map((sameAsYesterdayAnswer) => (
            <span
              key={sameAsYesterdayAnswer.id}
              className={correctionClass(fupActive, sameAsYesterdayAnswer, [sameAsYesterdayAnswerId], [day.same_as_yesterday_answer_id], [correctedDay.same_as_yesterday_answer_id])}
            >
              {sameAsYesterdayAnswer.abbreviation}
            </span>
          ))}
        </div>

        <div className="intercourse-code" data-intercourse-side={find_diagram_side(intercourses, intercourseId)}>
          {intercourses.filter((intercourse) => showCode(intercourse, [intercourseId], [correctedDay.intercourse_id])).map((intercourse) => (
            <span
              key={intercourse.id}
              className={correctionClass(fupActive, intercourse, [intercourseId], [day.intercourse_id], [correctedDay.intercourse_id])}
            >
              {intercourse.abbreviation}
            </span>
          ))}
        </div>
      </a>
      {showDailyEntry
        ?  <div className="daily-entry-popover position-absolute container">
            <CodeEntry
              correctedDay={correctedDay}
              day={day}
              dischargeObservations={dischargeObservations}
              dischargeObservationModifiers={dischargeObservationModifiers}
              essentialSameness={essentialSameness}
              form={form}
              fupActive={fupActive}
              intercourses={intercourses}
              menstrualFlows={menstrualFlows}
              observationFrequencies={observationFrequencies}
              pointOfChangeAnswers={pointOfChangeAnswers}
              sameAsYesterdayAnswers={sameAsYesterdayAnswers}
              submitForm={submitForm}
            />
          </div>
        : null
      }
    </td>
  );
}

