import React from 'react';
import { useForm } from 'react-hook-form';
import { onSubmit } from '../../ChartUtilities';
import CodeCell from './CodeCell';
import MCS from './MCS';
import PPP from './PPP';
import StampCell from './StampCell';

export default function Cycle({
  addFup,
  chart,
  cycle,
  chartDays,
  dischargeObservations,
  dischargeObservationModifiers,
  essentialSameness,
  fupActive,
  keys,
  intercourses,
  menstrualFlows,
  observationFrequencies,
  pdf,
  pointOfChangeAnswers,
  pushDay,
  sameAsYesterdayAnswers,
  setAddFup,
  stickers,
  subscriptionActive,
}) {
  const { register, getValues, handleSubmit, setValue, watch } = useForm({
    defaultValues: cycle,
  });

  const days = cycle
    .chart_days
    .filter(({ current_chart_day }) => current_chart_day)
    .sort((left, right) => left.day_number - right.day_number);

  const dayForms = Object.fromEntries(days.map((day) => {
    const form = useForm({
      defaultValues: day,
      mode: 'onSubmit',
    });

    return [day.id, form];
  }));

  const submitForm = async () => {
    const update = async (chart_cycle) => await onSubmit(`/app/chart_cycles/${cycle.id}`, { chart_cycle });
    // TODO: this should be the correct call
    // await handleSubmit(update);
    const chartCycle = getValues();
    await update(chartCycle);
  }

  return (
    <>
      <tr className="border-right border-dark">
        {days.map((day) => (
          <StampCell
            key={day.id}
            addFup={addFup}
            chart={chart}
            chartDays={chartDays}
            cycle={cycle}
            day={day}
            form={dayForms[day.id]}
            fupActive={fupActive}
            keys={keys}
            pushDay={pushDay}
            setAddFup={setAddFup}
            stickers={stickers}
            subscriptionActive={subscriptionActive}
          />
        ))}
        <PPP
          cycle={cycle}
          pdf={pdf}
          register={register}
          setValue={setValue}
          submitForm={submitForm}
          subscriptionActive={subscriptionActive}
          watch={watch}
        />
      </tr>

      <tr className="bottom-cycle-row border-right border-dark">
        {days.map((day) => (
          <CodeCell
            key={day.id}
            addFup={addFup}
            chart={chart}
            chartDays={chartDays}
            day={day}
            dischargeObservations={dischargeObservations}
            dischargeObservationModifiers={dischargeObservationModifiers}
            essentialSameness={essentialSameness}
            form={dayForms[day.id]}
            fupActive={fupActive}
            intercourses={intercourses}
            menstrualFlows={menstrualFlows}
            observationFrequencies={observationFrequencies}
            pointOfChangeAnswers={pointOfChangeAnswers}
            pushDay={pushDay}
            sameAsYesterdayAnswers={sameAsYesterdayAnswers}
            setAddFup={setAddFup}
            subscriptionActive={subscriptionActive}
          />
        ))}
        <MCS
          cycle={cycle}
          pdf={pdf}
          register={register}
          setValue={setValue}
          submitForm={submitForm}
          subscriptionActive={subscriptionActive}
          watch={watch}
        />
      </tr>
    </>
  );
}
