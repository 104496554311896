import React, { createRoot } from 'react-dom/client';
import ChartDiagram from './ChartDiagram';
import 'core-js/actual/array/to-sorted';

function renderChart() {
  const element = document.getElementById('chart-diagram');

  if (element) {
    const root = createRoot(element);
    root.render(<ChartDiagram />);
  }
}

document.addEventListener('turbolinks:load', () => {
  renderChart()
});
